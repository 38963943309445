<template>
  <div class="loader-container">
    <overlay-loader :loading="isLoading" />
    <section class="main-content">
      <tabs>
          <tab
            icon="account-icon"
            :name="$t('company.companies')"
            :selected="isSelected"
          >
            <hcc-table
              :enableSearch="true"
              :rowsPerPage="10"
              :actualPage="actualPage"
              @pageChanged="changePage"
              @add="create"
              :rows="rows"
              :columns="columns"
              @edit="editCompany"
            />
          </tab>
          <tab
            icon="account-icon"
            name="Fee por conversaciones"
            :selected="!isSelected"
          >
            <company-fee :rows="[]" @save-fee="updateCompanyFee"/>
          </tab>
      </tabs>
    </section>
    <hcc-modal
      @confirm="saveCompany"
      @opened="focusInput"
      @closed="clear"
      :confirmDisableButton="!enableSave"
      name="company"
      :title="edit ? $t('company.edit-title') : $t('company.title')"
    >
      <div class="modal-company">
        <hcc-input
          ref="name"
          class="modal-company__item"
          v-model="name"
          :label="$t('company.name')"
          requiredInput
        />
        <hcc-input
          min="0"
          type="number"
          class="modal-company__item"
          v-model.number="licences"
          :label="$t('company.licences')"
          requiredInput
          @keyup.enter="submit"
        />
        <div class="input-container modal-company__item">
          {{ demoTitle }}
          <hcc-toggle-switch
            size="sm"
            :value="isDemo"
            :name="`company-demo`"
            @change="toggleDemo"
          />
        </div>
        <hcc-input
          ref="userid"
          class="modal-company__item"
          v-model="userid"
          :label="$t('company.userid')"
        />
      </div>
    </hcc-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import createCompany from '@/graphql/mutations/company/create.gql';
import updateCompany from '@/graphql/mutations/company/update.gql';
import fullCompaniesGql from '@/graphql/queries/fullCompanies.gql';
import executeQuery from '@/utils/gql-api';
import errorHandler from '@/utils/error.handler';

export default {
  components: {
    OverlayLoader,
    Tab: () => import('@/components/TabsSingle.vue'),
    Tabs: () => import('@/components/TabsContainer.vue'),
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    CompanyFee: () => import('@/components/CompanyFee.vue'),
    HccToggleSwitch: () => import('@/components/shared/HccToggleSwitch/index.vue'),
  },
  data() {
    return {
      isSelected: true,
      isLoading: false,
      name: null,
      licences: null,
      isDemo: false,
      userid: null,
      rows: [],
      edit: false,
      company: null,
      actualPage: 1,
    };
  },
  mounted() {
    this.fetchCompanies();
    this.buildRows();
  },
  computed: {
    ...mapGetters({
      isOwner: 'shared/isOwner',
      companies: 'shared/getCompanies',
    }),
    enableSave() {
      return this.name != null && this.licences != null;
    },
    mutation() {
      return this.edit ? updateCompany : createCompany;
    },
    columns() {
      return [{
        label: this.$t('company.name'),
        field: 'name',
      }, {
        label: this.$t('app.campaigns'),
        field: 'totalCampaigns',
      }, {
        label: this.$t('app.users'),
        field: 'totalUser',
      }, {
        label: this.$t('company.licences'),
        field: 'maxUsers',
      }];
    },
    demoTitle() {
      return this.$t('company.demo');
    },
  },
  watch: {
    companies() {
      this.buildRows();
    },
  },
  methods: {
    ...mapMutations({
      setCompanies: 'shared/setCompanies',
    }),
    create() {
      this.edit = false;
      this.$modal.show('company');
    },
    editCompany(props) {
      this.company = props;
      this.edit = true;
      this.name = props.name;
      this.isDemo = props.demo;
      this.userid = props.salesAgent?.user;
      this.licences = props.maxUsers;
      this.$modal.show('company');
    },
    focusInput() {
      this.$refs.name.focus();
    },
    clear() {
      this.name = null;
      this.licences = null;
      this.company = null;
      this.isDemo = false;
      this.userid = null;
    },
    buildRequest() {
      let req = {
        name: this.name,
        maxUsers: this.licences,
        demo: this.isDemo,
        user: this.userid,
      };

      if (this.edit) {
        req = {
          ...req,
          id: this.company.id,
        };
      }

      return req;
    },
    async saveCompany() {
      await this.executeQuery(this.buildRequest(), this.mutation);
    },
    buildRows() {
      this.rows = this.companies.map((company) => {
        const { campaigns, users } = company;
        return {
          ...company,
          totalUser: campaigns.length,
          totalCampaigns: users.length,
        };
      });
    },
    async fetchCompanies() {
      this.isLoading = true;
      const companies = await executeQuery('fullCompanies', fullCompaniesGql, {}, false);
      this.isLoading = false;
      this.setCompanies(companies);
    },
    changePage(newPage) {
      this.actualPage = newPage;
    },
    submit() {
      if ((this.name.length > 0) && this.licences) {
        this.saveCompany();
        this.$modal.hide('company');
      }
    },
    async updateCompanyFee(props) {
      await this.executeQuery(props, updateCompany);
    },
    async executeQuery(variables, mutation) {
      try {
        this.isLoading = true;
        await this.$apollo.mutate({ mutation, variables });
        await this.fetchCompanies();
      } catch (err) {
        errorHandler.logErrors(err);
        this.$toasted.global.error(this.$t('common.error-saving'));
      } finally {
        this.isLoading = false;
      }
    },
    toggleDemo() {
      this.isDemo = !this.isDemo;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_campaign-configuration.scss";
@import "~styles/views/companies.scss";
</style>
